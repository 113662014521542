import { Link } from "@StarberryUtils";
import React, { useState, useRef, useEffect } from "react";
//import ScrollAnimation from "react-animate-on-scroll";
import ReactReadMoreReadLess from "react-read-more-read-less"
import google from "../../../images/home/reviews/google-logo.svg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import "./Reviews.scss"
import axios from "axios";
// markup
const Reviews = (props) => {
    let [latestReview, setReviews] = useState(typeof props.reviews != "undefined" ? props.reviews : []);
    const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
    let [reviewCount, setReviewCount] = React.useState(typeof props.reviewCount != "undefined" ? props.reviewCount : 0);
    let [averageRating, setAverageRating] = React.useState(typeof props.averageRating != "undefined" ? props.averageRating : 0);
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(()=>{
      let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      if( latestReview.length == 0){
        getitems(url);
      }
    })
    const sliderRef = useRef();
    const settings = {
        dots: true,
        lazyLoad: true,
        speed: 800,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        autoplay: false
    }

    const next = () => {
        sliderRef.current.slickNext();
    }
    const previous = () => {
        sliderRef.current.slickPrev();
    }
    const strapiconfig = {
        headers: {
            Authorization:
                `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
    }

    const getitems = async url => {
        try {
            const { data } = await axios.get(url,strapiconfig)// could be from env files  
            if (data) {
                const reviews = data.filter(rev => rev.comment != null && rev.starRating == "FIVE");
                reviews.sort((a, b) => b.updateTime - a.updateTime);
                setReviewCount(data.length);
                let sumOfScores = 0;
                data.map((review) => {
                    const reviewStringValue = review.starRating;
                    sumOfScores += wordToNumber[reviewStringValue];
                })
                setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
                setReviews(reviews.slice(0, 10));
            }

        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }

    const image_url = props.image.url

    let processedImages = JSON.stringify({});
    if (props.imagetransforms?.Modules?.Google_Reviews?.Reviews_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Modules.Google_Reviews.Reviews_Background_Image_Transforms;
    }

    return (
        <React.Fragment>
        
                <section className="reviews home-one-review">
                    <div className="reviews-img img-zoom">
                        {/* <picture>
                            <source media="(min-width:1200px)" srcSet={`${props.image.url}`} />
                            <source media="(min-width:768px)" srcSet={`${props.image.url}`} />
                            <img loading="lazy" src={`${props.image.url}`} alt={props.image.alternativeText} />
                        </picture> */}

                        {image_url &&
                            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Modules.Google_Reviews.Reviews_Background_Image.reviews_image" attr={{ alt: `${props.image?.alternativeText} - Dacha` }} imagetransformresult={processedImages} id={props.id} />
                        }
                    </div>
                    <div animateIn='fadeInLeft' animateOnce className="reviews-info">
                        <div className="reviews-wrapper">
                            <Link to="/about-dacha/customer-testimonials" className="google-brand">
                                <img loading="lazy" src={google} alt="google - Dacha" />
                            </Link>
                            <div className="ratings">
                                <span className="rating">
                                    {averageRating}/5 Rating from {reviewCount} Reviews
                                </span>
                                <Link to="/about-dacha/customer-testimonials" className="view-more">
                                    View more reviews
                                </Link>
                            </div>
                        </div>
                        <div className="review-slider">
                            <Slider {...settings} ref={sliderRef}>
                                {latestReview && latestReview.map((review, i) => {
                                    return (
                                        <div key={i} className="outline-none">
                                            <ul className="stars-icon">
                                                <li>
                                                    <i className="icon-stars">

                                                    </i>
                                                </li>
                                                <li>
                                                    <i className="icon-stars">

                                                    </i>
                                                </li>
                                                <li>
                                                    <i className="icon-stars">

                                                    </i>
                                                </li>
                                                <li>
                                                    <i className="icon-stars">

                                                    </i>
                                                </li>
                                                <li>
                                                    <i className="icon-stars">

                                                    </i>
                                                </li>
                                            </ul>
                                            <p className="text-md">

                                                {review.comment && (
                                                    <ReactReadMoreReadLess
                                                        charLimit={250}
                                                        readMoreText={"More"}
                                                        readLessText={"Less"}
                                                        readMoreClassName="read-more-less--more more-content"
                                                        readLessClassName="read-more-less--less more-content"
                                                    >
                                                        {review.comment}
                                                    </ReactReadMoreReadLess>
                                                )}
                                            </p>
                                            <div className="clients">
                                                <div className="clients-img">
                                                    <img loading="lazy" src={review.reviewer?.profilePhotoUrl} alt={`${review.reviewer?.displayName} - Dacha`} />
                                                </div>
                                                <div className="clients-info">
                                                    <h3 className="text-md-bold"> {review.reviewer?.displayName}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>

                            {/* <div className="slider-btn">
                                <i className="icon-video left" onClick={previous}></i>
                                <i className="icon-video right" onClick={next}></i>
                            </div> */}
                        </div>


                    </div>
                </section>
        </React.Fragment>
    )
}
export default Reviews